<template>
  <div>
    <br>
  </div>
</template>
<script>

export default {
  name: 'Dashboard',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {}
}
</script>

<style>
</style>
